<template>
  <div>
    <ExportBourseAuxLivresComponent
      @export="({anneeScolaire, cl}) => { 
        downloadingFile = {
          url: '/bourseAuxLivres', 
          params: { 
            perimetreId: cl.id, 
            anneeScolaireId: anneeScolaire.id 
          }
        }
      }"
    />
    <Downloader
      :downloading-file="downloadingFile"
      label="export Bourse aux Livres"
      @downloadSuccess="() => downloadingFile = null"
    />
  </div>
</template>

<script>
import ExportBourseAuxLivresComponent from '../../components/importsExports/ExportBourseAuxLivresComponent';
import Downloader from '../../components/partials/Downloader';
import { mapActions } from 'vuex';

export default {
  components: {
    ExportBourseAuxLivresComponent,
    Downloader,
  },
  data() {
    return {
      downloadingFile: null,
    };
  },
  created() {
    this.loadFilterAnneesScolaires();
    this.loadFilterDepartements();
  },
  methods: {
    ...mapActions('parametrages/anneeScolaire', [
      'loadFilterAnneesScolaires',
    ]),
    ...mapActions('cdpe', [
      'loadFilterDepartements',
    ]),
  },
};
</script>