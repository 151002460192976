<template>
  <v-card>
    <div class="pa-5 mb-5">
      <v-card-title
        primary-title
        class="title"
      >
        Export bourse aux livres
      </v-card-title>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="currentAnneeScolaire"
            :items="filterAnneesScolaires"
            item-text="libelle"
            label="Année scolaire *"
            return-object
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="currentCdpe"
            :items="getFilterDepartements"
            :item-text="item => `${item.code} - ${item.nom}`"
            label="Département *"
            return-object
            clearable
            :required="false"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="currentConseilLocal"
            :items="getFilterConseilsLocaux"
            :item-text="item => `${item.code} - ${item.nom}`"
            label="Conseil Local *"
            return-object
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-btn
            class="primary"
            :disabled="!currentAnneeScolaire || !currentCdpe || !currentConseilLocal"
            @click="$emit('export', {anneeScolaire: currentAnneeScolaire, cdpe: currentCdpe, cl: currentConseilLocal})"
          >
            Exporter
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'ExportBourseAuxLivresComponent',
  data() {
    return {
      currentAnneeScolaire: null,
      currentCdpe: null,
      currentConseilLocal: null,
    };
  },
  computed: {
    ...mapState('parametrages/anneeScolaire', {
      filterAnneesScolaires: state => state.filterAnneesScolaires,
    }),
    ...mapGetters('cdpe', [
      'getFilterDepartements',
    ]),
    ...mapGetters('conseilLocal', [
      'getFilterConseilsLocaux',
    ]),
    ...mapGetters('parametrages/anneeScolaire', [
      'getAnneeScolaireCourante',
    ]),
  },
  watch: {
    currentCdpe(val) {
      this.currentConseilLocal = null;
      if (val) {
        this.loadFilterConseilsLocaux(val.id);
      } else {
        this.resetFilterConseilsLocaux();
      }
    },
  },
  created() {
    this.currentAnneeScolaire = this.getAnneeScolaireCourante;
  },
  methods: {
    ...mapActions('conseilLocal', {
      loadFilterConseilsLocaux: 'loadFilterConseilsLocaux',
      resetFilterConseilsLocaux: 'resetState',
    }),
  },
};
</script>